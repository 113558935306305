<template>
  <div class="zchjs">
    <div class="header">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/caigou' }">采购</el-breadcrumb-item>
        <el-breadcrumb-item >详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="descriptions flex">
        <div class="flex-1 xiangqing">
          <div class="flex">
            <div class="datitle flex-1">
              <el-tag style="float: left" size="mini" effect="dark">{{datas.typeName}}</el-tag>
              {{ datas.title }}
            </div>
            <shoucang  articleType="purchase" :articleId="$route.query.id"></shoucang>
          </div>
          <div  class="datitle1">发布时间：{{datas.createTime}}</div>
          <div class="compdesc">
            <el-descriptions class="margin-top" :colon="false"  :column="1" >
              <el-descriptions-item label="产品名称">{{datas.productName}}</el-descriptions-item>
              <el-descriptions-item label="详细说明"><div v-html="datas.detail"></div></el-descriptions-item>
              <el-descriptions-item label="联系人">{{datas.contacts}}</el-descriptions-item>
              <el-descriptions-item label="需求企业">{{datas.enterpriseName}}</el-descriptions-item>
              <el-descriptions-item label="联系电话">{{datas.phone}}</el-descriptions-item>
              <el-descriptions-item label="附件">
                <fujian  :files="datas.files"></fujian>
              </el-descriptions-item>
            </el-descriptions>
            <div v-if="datas.typeName=='集采'">
              <el-table
                  :data="tableData"
                  style="width: 100%;margin: 20px 0">
                <el-table-column
                    prop="enterpriseName"
                    label="企业名称"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="enrollNumber"
                    label="数量">
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="提交日期">
                </el-table-column>
              </el-table>
            </div>
            <div  style="float: right;margin-left: 10px"  v-if="datas.typeName=='自采'"> <el-button type="primary" size="large" @click.stop.native="mai()">我要卖</el-button></div>
           <div  v-if="datas.typeName=='集采'" style="float: right;"> <el-button type="primary" size="large" @click.stop.native="zixun()">我要报名采集</el-button></div>
        </div>
          <div class="mianze" style="margin-top: 150px;font-size: 12px;color: #4C4C4C;line-height: 25px">
            <h3>免责声明：</h3>
            <p>本网站作为信息服务平台，所有信息均由企业自行上传、发布。</p>
            <p> 用户发布信息的真实性、准确性等不作保证。</p>
            <p> 因上述原因导致的对其他用户或第三方造成的损失，本网站不承担责任。</p>
          </div>
        </div>
        <div class="tuijian">
            <h3>推荐采购</h3>
            <el-divider></el-divider>
             <div v-for="(li,index) in datas.recommendList" @click="goxq(li)" :key="index">
               <div>{{li.title}}</div>
               <div style="color: #C4C4C4;font-size: 12px;padding-top: 5px">采购总量{{li.minNumber}}</div>
               <el-divider></el-divider>
             </div>
        </div>
      </div>

    </div>
    <el-dialog title="发送" width="600px" append-to-body :visible.sync="dialogTableVisible">
      <el-form ref="form" :model="shen" label-width="120px" :rules="rules">
        <el-form-item label=" 报名留言" prop="detail">
          <el-input rows="5"  style="width: 300px" type="textarea" v-model="shen.detail"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input   v-model="shen.unit"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="enrollNumber">
          <el-input type="number" v-model="shen.enrollNumber"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input   v-model="shen.contacts"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input type="number" v-model="shen.phone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-top: 20px" @click="submitForm()">保存</el-button>
          <el-button @click="dialogTableVisible=!dialogTableVisible">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="我要卖" width="500px" append-to-body :visible.sync="dialogTableVisiblezx">
      <el-form ref="formzx" :model="shenzx" label-width="100px" :rules="ruleszx" :inline="true">
        <el-form-item label="咨询详情" prop="detail">
          <el-input rows="5" class="inputWidth mb8" type="textarea" v-model="shenzx.detail"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input   v-model="shenzx.contacts" class="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="手机号"   prop="phone">
          <el-input  v-model.number="shenzx.phone" class="inputWidth"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin: 20px 0 0 160px" @click="submitFormzx()">保存</el-button>
          <el-button @click="dialogTableVisiblezx=!dialogTableVisiblezx">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {enterprisedetail, parklist} from "../../request/moudle/park";
import fujian from "../common/fujian.vue";
import {purchasedetail, purchasesubmitconsult, tenderingdetail} from "../../request/moudle/demand";
import shoucang from "../../components/shoucang.vue";
export default {
  name: "zchj",
  components: {shoucang, fujian},
  data(){
    return {
      datas:'',
      tableData:[],
      shen:{},
      shenzx:{},
      rules: {
        unit: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        enrollNumber: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },
      ruleszx: {
        detail: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
          { type: 'number', message: '手机号必须为数字值'}
        ]
      },
      dialogTableVisible:false,
      dialogTableVisiblezx:false
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    goxq(item){
      this.$router.push({
        path:'/caigouxq',
        query:{
          id:item.id,
        }
      })
      this.$router.go(0)
    },
    submitFormzx(){
      this.$refs.formzx.validate((valid) => {
        if (valid) {
          this.shenzx.productType = "purchase-quote"
          this.shenzx.productId = this.$route.query.id
          this.$api.logistics.consultMessage(this.shenzx).then((res)=>{
            this.dialogTableVisiblezx=false
            this.$message({
              type: "success",
              message: "提交成功!"
            });
          })
        }
      })
    },
    submitForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.shen.purchaseId=this.$route.query.id
          this.$api.demand.purchasesubmitconsult(this.shen).then((res)=>{
            this.dialogTableVisible=false
            this.$message({
              type: "success",
              message: "提交成功!"
            });
          })
        }
      })
    },
    zixun(item){
      this.shen={}
      this.dialogTableVisible=true
    },
    mai(item){
      this.shenzx={}
      this.dialogTableVisiblezx=true
    },
    goBack() {
      this.$router.go(-1)
    },
    loadxq(){
      this.$api.demand.purchasedetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.tableData=res.data.enrollList||[]
        this.datas=res.data;
        console.log(this.datas)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 120px;
}

/deep/ .el-input{
  width: auto;
}
.zchjs{
  background: #F5F5F5;
}
.xiangqing{
  background: #fff;
  padding: 20px;
}
.compdesc{
  margin-top: 50px;
}
.tuijian{
  width: 310px;
  margin-left: 60px;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.datitle{
  font-weight: bolder;
  text-align: center;
}
.datitle1{
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
.header{
  height: 170px;
  background: url("../../assets/banner/gxjy.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 20px auto;
  /deep/ .el-col{
    padding: 15px ;
  }
  .descriptions{
    margin-top: 20px;
  }
}
</style>